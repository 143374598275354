/*
|-----------------------
| 	    CONTENT
|-----------------------
|
*/

/* MAIN */
main {width: 100%;padding-top:100px;
    section {padding-top: 12.5em;padding-bottom: 12.5em;}
    .intro {display:flex;flex-direction: column;justify-content: center;height: calc(100vh - 100px);padding-left: 20%;padding-right: 20%;background-size:cover;
        .descr {max-width: 45%;}
        .btn-icon {display: flex;align-items:center;padding-top: 5em;
            p {padding-left: 1em;}
        }
    }
    .explain {height: auto;padding-left: 20%;padding-right: 20%;
        .content {display:flex;justify-content: center;padding-bottom: 5em;
            .left {width: 40%;margin-right: 7.5em;}
            .right {width: 60%;
                p {padding-top: 1.5em;}
            }
        }
    }
    .about {
        .swiper-container {
            .swiper-slide {padding-top: 7.5em;
                h2 {color: $light-grey;}
                .descr {opacity: 0;transition: all .25s ease-in-out;}
                &-active {
                    h2 {color: $black;}
                    .descr {opacity: 1;transition: all 1s ease-in-out;}
                }
                &:first-child() {margin-left: 20%;}
            }
            .swiper-button-prev, .swiper-button-next {top: 0;margin-top: 0;background-size: 5px;}
            .swiper-button-prev {left: 20%;right: inherit;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23"+$swiper-svg-main+"'%2F%3E%3C%2Fsvg%3E");
                &:hover {background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23"+$swiper-svg-white+"'%2F%3E%3C%2Fsvg%3E");}
            }
            .swiper-button-next {left: calc(20% + 50px);right: inherit;margin-left: 2em;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23"+$swiper-svg-main+"'%2F%3E%3C%2Fsvg%3E");
                &:hover {background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23"+$swiper-svg-white+"'%2F%3E%3C%2Fsvg%3E");}
            }
        }
    }
    .band {padding-top: 0;padding-bottom: 0;
        .figure {
            max-height: 800px;
        }
    }
    .bannier {height: auto;padding-left: 20%;padding-right: 20%;text-align: center;
        p {
            padding-bottom: 1.5em;
            &:last-child() {padding-bottom: 5em;}
        }
    }
    .team {height: auto;padding-left: 15%;padding-right: 15%;text-align: center;
        .grid {display: flex;flex-wrap: wrap;align-items: center; justify-content: center;
            .box {padding: 1.5em 2.5em;margin: 1em;
                .subtitle-big {text-align: left;}
                .subtitle-small {text-align: right;}
                .figure {margin: 2em;}
                &:last-child {
                    .subtitle-big {width: 50%;}
                    &:hover {color: white;}
                    svg {padding: 5.7em;}
                }
            }
        }
    }
    .news {height: auto;
        .swiper-container {padding-top: 95px;
            .swiper-scrollbar {display: none;}
            .swiper-slide {cursor: grab;
                .content {padding-left: 1em;
                    h3 {color: $black;padding-top: 1.5em;padding-bottom: 1.5em;}
                    .descr {max-width: 90%;}
                }
                &:first-child() {margin-left: -13.75%;}
            }
            .swiper-button-prev, .swiper-button-next {top: 0;margin-top: 0;background-size: 5px;}
            .swiper-button-prev {left: 20%;right: inherit;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23"+$swiper-svg-main+"'%2F%3E%3C%2Fsvg%3E");
                &:hover {background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23"+$swiper-svg-white+"'%2F%3E%3C%2Fsvg%3E");}
            }
            .swiper-button-next {left: calc(20% + 50px);right: inherit;margin-left: 2em;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23"+$swiper-svg-main+"'%2F%3E%3C%2Fsvg%3E");
                &:hover {background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23"+$swiper-svg-white+"'%2F%3E%3C%2Fsvg%3E");}
            }
        }
    }
    .contact {
        .ask {opacity:0;height:0;overflow:hidden;
            &.open{opacity:1;height:auto;overflow:inherit;}
            .gform_fields {display: flex; flex-wrap: wrap; align-items: end;
                .gfield {width: 100%;margin-top: 0;
                    &:nth-child(1), &:nth-child(2), &:nth-child(3)  {
                        width: 50%;
                    }
                    .ginput_container {
                        input, textarea {color: $white;background: none;border: none;border-bottom: rgba(255, 255, 255, 0.5) solid 1px;
                            &:focus {border-bottom: $white solid 1px;}
                        }
                        textarea {height: 35px;transition: all .25s ease-in-out;
                            &:focus {height: 200px;}
                        }
                    }
                    .ginput_container_fileupload {
                        input {border-bottom: none;}
                        span {display: none;}
                        ::-webkit-file-upload-button {color: #00667A;background-color: #FFFFFF;transition: all .25s ease-in-out;padding: 10px 25px;margin-right: 25px;border-radius: 25px;white-space: nowrap;}
                    }
                    .gfield_label {transform: translateY(37px);font-family: $main-font-family, $second-font-family, sans-serif;color: $white;font-size: inherit;line-height: inherit;font-weight: 100;opacity: .5;
                        .gfield_required {color: $white;}
                    }
                    &:not(:last-child()) {margin-bottom: 1.5em;}
                    .gfield_checkbox li{display:flex;align-items:flex-start;
                        label{color:$white;padding-left:15px;opacity: .5;
                            a{color:$white;text-decoration:underline;}
                        }
                    }
                }
            }
            .gform_footer {
                .gform_button {float: right;color: $main-color;background-color: $white;transition: all .25s ease-in-out;padding: 10px 25px;border-radius: 25px;white-space: nowrap;
                    &:hover {color: $white;background-color: $main-color;}
                }
            }
        }
    }
    .article{padding: 8em 15%;}
}

.g-recaptcha{margin-top:-50px;}

#gmap{height:600px;width:100%;}
.gm-style *{box-shadow:rgba(0, 0, 0, 0) 0 0 0 !important;}
.gm-style .gm-style-iw-t::after {
    background: none;    
    background-image: url("../../src/img/content/icon/marker.svg");
    background-repeat: no-repeat;
    background-size: 30px;
    top: -35px;
    left: -10px;
    transform: rotate(0);
    box-shadow: none;
    width: 100px;
    height: 100px;
}
.gm-style .gm-style-iw {display: block !important;background-color: $white !important;top: 182.5px !important;left: 245px !important;width:400px !important;min-height: 215px !important;padding: 40px 35px;border-radius:5px;overflow: inherit;box-shadow: 2.5px 2.5px 2.5px 1px rgba(178, 178, 178, 0.4) !important;
    &::before{
        background: white;
        content: "";
        height: 25px;
        left: 0;
        position: absolute;
        top: 10%;
        transform: translate(-50%,0) rotate(-45deg);
        width: 25px;
    }
    button{display:none !important;}
	.adress-popup{text-align:left;
		p{margin:0 0 5px 0;padding:0;color:$dark-grey;font-size: 16px;line-height: 27.5px;
			&.title{font-weight: 700;margin-bottom:20px;}
			&.address{margin-top:0;}
		}
        img{margin-bottom:20px;width:110px;}
	}
}

/* cookie */
#cookie{display:none;position:fixed;left:0;right:0;bottom:0;background:$white;@include box-shadow(0 2px 8px 0 rgba(0, 0, 0, 0.19));z-index:9999;
    .container{display:flex;align-items:center;justify-content:space-between;padding: 10px 20px;
		.btn-text{display:block;margin:15px;text-align:center;}
	}
}
