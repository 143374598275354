/*
|-----------------------
| 	   RESPONISVE
|-----------------------
|
*/

/* RESPONISVE */
@media (max-width: 980px) {
    header {padding: .5em 3.5em;
        nav {
            #nav {position: fixed;top:100px;left:0;right:0;height:calc(100vh - 100px);display: none;flex-direction: column;padding: 1.5em;background: $white;
                li {padding-left: 0;padding-bottom: .5rem;margin-bottom:1rem;text-align: center;}
            }
            #menu {
                &.mobile-menu{display:block;}
            }

            &.open{
                .mobile-menu{
                    span{background:$white;}
                    span:nth-child(2){opacity: 0;}

                    span:nth-child(1){
                      -webkit-transform: translateY(10px) rotate(45deg);
                      -ms-transform: translateY(10px) rotate(45deg);
                      -o-transform: translateY(10px) rotate(45deg);
                      transform: translateY(10px) rotate(45deg);
                    }

                    span:nth-child(3){
                      -webkit-transform: translateY(-10px) rotate(-45deg);
                      -ms-transform: translateY(-10px) rotate(-45deg);
                      -o-transform: translateY(-10px) rotate(-45deg);
                      transform: translateY(-10px) rotate(-45deg);
                    }
                }
            }
        }
        .btn-icon {position: fixed;bottom: 2.5em;right: 3.75em;flex-direction: column;
            p {display: none;}
        }
    }
    main {
        h1 {font-size: 2em;}
        section{padding-top:5%;padding-bottom:5%;}
        .intro {padding-left:5%;padding-right:5%;height:auto;
            .descr { max-width: 100%;}
        }
        .explain {padding-left:5%;padding-right:5%;
            .content {flex-direction: column;
                .left {width: 100%;}
                .right {width: 100%;}
            }
        }
        .team{padding-left:5%;padding-right:5%;}
        .news {
            .swiper-container {
                .swiper-slide {padding-left: 10%; padding-right: 10%;
                    .content {padding-left: 0; padding-right: 0;}
                    .descr {max-width: 100%;}
                    &:first-child() {margin-left: 0;}
                }
            }
        }
    }
    #contact{padding:7.5em 0;}
    .gm-style .gm-style-iw-t::after {
        top: -35px;
        left: 0px;
    }
    .gm-style .gm-style-iw {top: 260px !important; left: 15px !important;
        &::before{
            left: 50%;
            top: -6%;
        }
    }

    footer#footer .footer_part1,
    footer#footer .footer_part2{margin:5%;}

}
@media (max-width: 767px) {
    header {padding: .5em 1.25em;
        .btn-icon {right: 1.25em;padding-top: 2em;}
    }
    main {
        section {padding-top: 7.5em;padding-bottom: 7.5em;}
        .subtitle-small {padding-bottom: .5em;}
        .title-big {font-size: 2em;padding-bottom: .5em;}
        .intro {padding-left: 10%;padding-right: 10%;
            .btn-icon {bottom: 1.25em;right: 1.25em;padding-top: 2em;}
        }
        .explain {padding-left: 10%;padding-right: 10%;}
        .banner {padding-left: 10%;padding-right: 10%;}
        .about {
            .swiper-container {
                .swiper-slide {padding-left: 10%; padding-right: 10%;
                    &:first-child() {margin-left: 0;}
                }
            }
        }
        .contact {
            .ask {
                .gform_fields {display: flex; flex-wrap: wrap; align-items: end;
                    .gfield {
                        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    footer#footer .footer_part1{flex-direction:column;
        > div, nav{flex-direction:column;align-items:center;width:100%;margin:0;}

        nav{
            ul{margin:20px 0;align-items:center;}
        }
        .follow .network{flex-direction:column;align-items:center;
            a{margin:0 0 20px 0;}
        }
    }
}

@media (max-width: 400px) {
    #input_1_11 {
        margin-left: -40px;
    }
}