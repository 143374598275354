/*
|-----------------------
| 	     HEADER
|-----------------------
|
*/

header {position: fixed;display: flex;flex-wrap: nowrap;align-items:center;justify-content: space-between;width: 100%;height: 100px;padding: .5em 3.5em;background: $white;z-index: 10;
    figure {min-width: 150px;}
    nav {
        #nav {display: flex;flex-wrap: nowrap;align-items:center;
            li {padding-left: 1.5rem;text-align: center;}
        }
        #menu {
            &.mobile-menu{display:none;position:relative;width:30px;height:20px;margin-right:10px;z-index:950;cursor:pointer;
                span{display:block;position:absolute;left:0;width:100%;height:2px;background:$main-color;@include transition(all, 0.3s);}
                span:nth-of-type(1){top:0;}
                span:nth-of-type(2){top:10px;}
                span:nth-of-type(3){top:20px;}

            }
        }
    }
}

#help.btn-icon {position: fixed;bottom: 3em;right: 3.5em;z-index:9999;
    p {padding-right: 1em;}
}
