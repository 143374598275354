/*
|-----------------------
| 	     IMAGES
|-----------------------
|
*/

.figure {
    display: flex;justify-content: center;align-items: center;overflow: hidden;
    &-round {
        height: 215px;width: 215px;border-radius: 100%;
        .image {transform: scale(2);}
    }
    .image {
        &-vertical {height: 100%;width: auto;max-width: inherit;}
        &-horizontal {height: auto;width: 100%;max-width: inherit;}
    }
}