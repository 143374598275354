/*
|-----------------------
| 	     BUTTONS
|-----------------------
|
*/

.btn {
    &-white {
        color: $main-color;background-color: $white;transition: all .25s ease-in-out;
        &:hover {color: $white;background-color: transparent;border: $white solid 1px;}
    }
    &-light {
        color: $main-color;background-color: $light-grey;transition: all .25s ease-in-out;
        &:hover {color: $white;background-color: $main-color;}
    }
    &-main {
        color: $white;background-color: $main-color;transition: all .25s ease-in-out;
        &:hover {background-color: $light-grey;transition: all .25s ease-in-out;}
    }
    &-text {
        padding: 10px 25px;border-radius: 25px;white-space: nowrap;
        svg {width: auto!important;padding-left: 25px;font-size: $main-font-size;transition: all .25s ease-in-out;}
        &:hover {
            color: $white;
            svg {transform: translateX(5px);}
        }
    }
    &-icon {
        display: flex;align-items:center;
        &-small {display: flex;justify-content: center;align-items: center;width: 50px;height: 50px;padding: 10px 15px;border-radius: 50px;}
        &-big {display: flex;justify-content: center;align-items: center;width: 70px;height: 70px;padding: 10px 15px;border-radius: 50px;}
        svg {width: 25px!important;height: 25px;}
        p {padding: 0;}
    }
}