/*
|-----------------------
| 	    SUBTITLES
|-----------------------
|
*/

.subtitle {
    &-small {padding-bottom: 1.5em;font-size: $subtitle-small-font-size;text-transform: uppercase;}
    &-big {font-size: $subtitle-big-font-size;}
}