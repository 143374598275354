/*
|-----------------------
| 	    VARIABLES
|-----------------------
|
*/

/* COLORS */
$white: #FFFFFF;
$light-grey: #F0F1F1;
$grey: #A4A8AB;
$dark-grey: #5B6870;
$light-grey: #EEEEEE;
$black: #282A34;

$main-color: #00667A;
$second-color: #313749;

$swiper-svg-white: "FFFFFF";
$swiper-svg-main: "00667A";
/* ----- */

/* FONT */
$main-font-family: 'Helvetica';
$second-font-family: 'Arial';

$main-font-size: 1em;
$title-small-font-size: 2.5em;
$title-big-font-size: 3.75em;
$subtitle-small-font-size: .875em;
$subtitle-big-font-size: 1.5em;

$main-font-weight: 200;
$second-font-weight: 400;

$main-line-height: 1.5;
/* ---- */
