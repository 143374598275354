/*
|-----------------------
| 	     FOOTER
|-----------------------
|
*/

// FOOTER
footer#footer{width: 100%;height: auto;background: $white;
    .footer_part1,
    .footer_part2{display:flex;flex-wrap: nowrap;align-items:start;justify-content: space-between;margin:5em 15%;
        > div{display:flex;}
    }

    .footer_part2{border-top:1px solid $light-grey;margin:0 15%;padding:2em 0;
        > div,span,a{font-size:12px;color:$dark-grey;}
    }

    figure {min-width: 150px;align-items:flex-start;overflow: inherit;
        img{max-width:150px;}
    }
    p {font-weight: 400;padding-bottom: .5em;}
    nav {width: 75%;display: flex;flex-wrap: nowrap;align-items:start;justify-content: start;margin-left: 7.5em;margin-right: 5em;
        ul {display: flex;flex-direction: column;flex-wrap: nowrap;align-items:start;
            &:first-child {margin-right: 5em;}
        }
    }
    .follow {display: flex;flex-direction: column;flex-wrap: nowrap;align-items:start;justify-content: space-between;
        .network {display: flex;flex-wrap: nowrap;align-items:start;
            a{margin-right:1.5em;
                figure {width:15px;min-width:15px;height:15px;min-height:15px;
                    &:last-child{margin-right:0;}
                }
            }
        }
    }
}

#cookie-notice {
    display: flex;
    align-items: center;
    padding: 2.5px 0;
}