/*
|-----------------------
| 	     COLORS
|-----------------------
|
*/

.color {
    &-white {color: $white;}
    &-light {color: $light-grey;}
    &-grey {color: $grey;}
    &-dark {color: $dark-grey;}
    &-black {color: $black;}
    &-main {color: $main-color;}
    &-second {color: $second-color;}
}
.bg {
    &-white {background-color: $white;}
    &-light {background-color: $light-grey;}
    &-grey {background-color: $grey;}
    &-dark {background-color: $dark-grey;}
    &-black {background-color: $black;}
    &-main {background-color: $main-color;}
    &-second {background-color: $second-color;}
}