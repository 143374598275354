/*
|-----------------------
| 	     TITLES
|-----------------------
|
*/

.title {
    &-small {padding-bottom: 1em;font-size: $title-small-font-size;}
    &-big {max-width: 90%;padding-bottom: 1em;font-size: $title-big-font-size;}
}