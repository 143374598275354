/*
|-----------------------
| 	    DEFAULTS
|-----------------------
|
*/

body {
  font-family: $main-font-family, $second-font-family, sans-serif;
  color: $black;
  font-size: $main-font-size;
  line-height: $main-line-height;
  font-weight: $main-font-weight;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
}

h1 {
  font-weight: $second-font-weight;
}

a {
  color: $black;
  &:hover {
    color: $main-color;
  }
}
